import React from 'react';
import { TabList } from 'tyb';
import { connect } from 'react-redux';

import NotAudit from './components/NotAudit';
import AlreadyAudit from './components/AlreadyAudit';
import './index.scss';

@connect(
  state => ({
    auditUnauditeds: state.batchApply.auditUnauditeds,
    auditPasses: state.batchApply.auditPasses,
  })
)
class CodePackageAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabList: [
        {
          value: 'notaudit',
          text: '未审核'
        },
        {
          value: 'alreadyaudit',
          text: '已审核'
        },
      ],
      nowTab: 'notaudit',
    }
  }

  changeTab = (value) => {
    this.setState({
      nowTab: value
    })
  }


  render() {
    const { tabList, nowTab } = this.state;
    const { auditUnauditeds = {}, auditPasses = {} } = this.props;
    return (
      <div className="CodePackageAudit">
        <div className="main-block">
            <TabList
              list={[
                {
                  value: 'notaudit',
                  text: `未审核(${auditUnauditeds.totalElements || 0})`
                },
                {
                  value: 'alreadyaudit',
                  text: `已审核(${auditPasses.totalElements || 0})`
                },
              ]}
              value={nowTab}
              onChange={this.changeTab}
            >
            </TabList>
            <NotAudit isShow={nowTab === 'notaudit'} />
            <AlreadyAudit isShow={nowTab === 'alreadyaudit'} />
          </div>
      </div>
    )
  }
}

export default CodePackageAudit;