import React from 'react';
import { reduxForm } from 'redux-form';

import { FormGroupField } from 'tyb';
import { Textarea, Dialog } from 'tyb';
import './NotPassForm.scss';

const required = (value) => {
  return (!value || (value + '').trim() === '' ? '请填写备注' : undefined);
  // return (value || typeof value === 'number' ? undefined : '请填写备注');
}

const max = (max) => (value) => {
  return value && value.trim().length > max ? `至多${max}字符` : undefined
}

const maxLength100 = max(100);

const NotPassForm = props => {
  const { onSubmit, handleSubmit, handleCancel } = props;
  return (
    <Dialog
          className="NotPassForm"
          style={{ width: '420px' }}
          title="不通过原因"
          visible={true} 
          okText="确认"
          cancelText="取消"
          onOk={handleSubmit(onSubmit)}
          onCancel={handleCancel}
        >
      <div className="main-block" style={{ textAlign: 'center' }}>
        <FormGroupField 
          name="remark"
          component={Textarea}
          placeholder="备注"
          validate={[required, maxLength100]}
        />
      </div>
      
    </Dialog>
  )
}

export default reduxForm({
  form: 'NotPassForm',
})(NotPassForm);