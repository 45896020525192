import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, Dialog, Button, Tip } from 'tyb'

const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { loadAuditUnauditeds, audit, auditPass, viewAudit } = iceStarkData.store.get('commonAction')?.batchApply;
const { updateAuditPassDialogStatus, updateNotPassDialogStatus } = iceStarkData.store.get('commonAction')?.ui;
import SearchBar from '../../components/SearchBar';
import ViewDialog from '../../components/ViewDialog';
import NotPassForm from './NotPassForm';
import { formatDate } from 'ucode-utils';
import { handSortOrder } from 'ucode-utils';
import '../index.scss';
import enums from '@/assets/enum.json';
@connect(
  state => ({
    viewAudit: state.batchApply.viewAudit,
    factorySelections: state.factory.factorySelections,
    auditUnauditeds: state.batchApply.auditUnauditeds,
    loadAuditUnauditedsLoading: state.batchApply.loadAuditUnauditedsLoading,
    auditResponse: state.batchApply.audit,
    auditPassDialogStatus: state.ui.auditPassDialogStatus,
    auditPassLoading: state.batchApply.auditPassLoading,
    notPassDialogStatus: state.ui.notPassDialogStatus,
    permissionIds: state.users.permissionIds||[],
  }),
  {
    loadSelections: loadSelections.REQUEST,
    loadAuditUnauditeds: loadAuditUnauditeds.REQUEST,
    audit: audit.REQUEST,
    auditPass: auditPass.REQUEST,
    updateAuditPassDialogStatus,
    updateNotPassDialogStatus,
    loadViewAudit: viewAudit.REQUEST
  }
)
class NotAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCreatedTimeOrder: null,
      factoryNameFilter: [],
      tableColumns: this.getTabColumns(),
      perPageCountList: [20, 50, 100],
      params: {
        idOrName: '',
        auditStart: '',
        auditEnd: '',
        factoryIds: '',
        page: 0,
        size: 20,
        sort: ''
      },
      viewDialogStatus: false,
      viewObject: {}
    }
  }

  componentDidMount() {
    const { loadSelections, loadAuditUnauditeds } = this.props;
    loadSelections({factoryType: 'PRINT'});
    loadAuditUnauditeds(this.state.params);

  }

  changeSearchBox = (e) => {    
    const params = this.state.params;
    params.idOrName = e.target.value;
    this.setState({
      params
    })
  }

  changeDataRange = (value) => {
    const params = this.state.params;
    params.applyStart = isNaN(new Date(value.fromFormat).getTime()) ? '' :  new Date(value.fromFormat).getTime();
    params.applyEnd = isNaN(new Date(value.toFormat).getTime()) ? '' :  new Date(value.toFormat).getTime();
    this.setState({
      params
    })
  }

  search = () => {
    const params = this.state.params;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadAuditUnauditeds(params);
  }

  clear = () => {
    const params = this.state.params;
    params.idOrName = '';
    params.applyStart = '';
    params.applyEnd = '';
    this.setState({
      params
    })
  }

  getTabColumns = () => {
    const { permissionIds } = this.props; 
 
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: '码包名称/ID',
        render: (value, record) => <div>
          <div title={record.name}><span className="text-overflow">{record.name}</span></div>
          <div title={value}><span className="text-overflow">{value}</span></div>
        </div>
      },
      {
        key: 'totalTagCount',
        dataIndex: 'totalTagCount',
        title: '申请码量',
        render: (value, record) => {
          if (record.combinationTagType == 'SINGLE_TAG') {
            return <div>
              <div title={value}><span className="text-overflow">{value}</span></div>
            </div>
          }
          else {
            return <div>
            <div title={`${value}(${record.unitCount}套)`}><span className="text-overflow">{value}({record.unitCount}套)</span></div>
          </div>
          }
        }
      },
      {
        key: 'eseConfigName',
        dataIndex: 'eseConfigName',
        title: '码印刷规格',
      },
      {
        key: 'combinationTagType',
        dataIndex: 'combinationTagType',
        title: '规格类型',
        render: (value) => <div title={enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}>
        <span>{enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}</span>
    </div>
      },
      {
        key: 'factoryName',
        dataIndex: 'factoryName',
        title: '包材印刷厂',
      },
      {
        key: 'applyName',
        dataIndex: 'applyName',
        title: '申请人',
      },
      {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: '申请时间',
        render: (value) => <div title={formatDate(value)}>
          <span className="text-overflow">{formatDate(value)}</span>
        </div>
      },
      {
        key: 'remark',
        dataIndex: 'remark',
        title: '申请备注',
      },
      {
        key: 'op',
        dataIndex: 'op',
        title: '操作',
        render: (value, record) =>
          <div className="table-operate-btn">
            <span onClick={() => {
              this.setState({
                viewDialogStatus: true,
                viewObject: record
              })
            }}>查看</span>
            {
              permissionIds.includes('code.batchApply.auditor.audit') &&
              <span onClick={() => {
                this.props.loadViewAudit(record.id);
                this.props.updateAuditPassDialogStatus(true);
                this.setState({
                  applyId: record.id,
                  applyVersion: record.version
                })
              }}>通过</span>
            }

            {
              permissionIds.includes('code.batchApply.auditor.audit') &&
              <span onClick={() => {
                this.setState({
                  applyId: record.id,
                  applyVersion: record.version
                })
                this.props.updateNotPassDialogStatus(true);
              }}>不通过</span>
            }
            
            
          </div>
      },
    ]
  }

  handleSortCreatedTime = () => {
    const newOrder = handSortOrder(this.state.sortCreatedTimeOrder);
    const params = this.state.params;
    const sort = `createdTime,${newOrder}`;
    params.sort = sort;
    this.setState({ params, sortCreatedTimeOrder: newOrder });
    this.props.loadAuditUnauditeds(params);
  }

  renderCreatedTimeSortHeader = (col) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state.sortCreatedTimeOrder}
        onSort={this.handleSortCreatedTime}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    )
  }

  handleFactoryNameFilter = (value) => {
    
    const params = this.state.params;
    params.factoryIds = value;
    params.page = 0;
    this.setState({
      params,
      factoryNameFilter: value
    });
    this.props.loadAuditUnauditeds(params);
  }

  renderFactoryNameFilterHeader = (col) => {
    const { factorySelections = [] } = this.props;
    const list = factorySelections.map(v => {
      return {
        text: v.name,
        value: v.id
      }
    })

    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={this.state.factoryNameFilter}
        onFilter={this.handleFactoryNameFilter}
      >
        {col.title}
      </DataTable.FilterableColumnHeader>
    )
  }



  getColumns = () => {
    return this.getTabColumns().map(column => {
      if (column.key === 'createdTime') {
        return {
          ...column,
          headerRender: this.renderCreatedTimeSortHeader
        }
      } else if (column.key === 'factoryName') {
        return {
          ...column,
          headerRender: this.renderFactoryNameFilterHeader
        }
      } else {
        return column;
      }
    });
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loadAuditUnauditeds(params);

  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadAuditUnauditeds(params);

  }

  handleCancelViewDialog = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {}
    })
  }

  handleOkAuditPassDialog = () => {
    const { applyId, applyVersion, params: loadListParams } = this.state;
    loadListParams.page = 0
    const params = {
      auditPassParams: {
        applyId,
        auditStatus: 'PASS',
        version: applyVersion
      },
      loadListParams
    }
    this.setState({
      params: loadListParams
    })
    this.props.auditPass(params);
  }

  handleCancelAuditPassDialog = () => {
    this.props.updateAuditPassDialogStatus(false);
  }

  handleSubmitNotPassForm = (values) => {
    const auditStatus = 'RETURN';
    const result = {
      ...values,
      auditStatus,
      applyId: this.state.applyId,
      version: this.state.applyVersion,
    }
    const params = this.state.params;
    params.page = 0;
    this.setState({
      params
    })
    this.props.audit({
      auditParams: result,
      loadListParams: params
    });
  }

  handleCancelNotPassForm = () => {
    this.props.updateNotPassDialogStatus(false);
  }

  render() {
    const { params, perPageCountList, viewDialogStatus, viewObject } = this.state;
    const { isShow, auditUnauditeds = {}, loadAuditUnauditedsLoading, auditPassDialogStatus, notPassDialogStatus, viewAudit = {}, auditPassLoading } = this.props;
    const auditUnauditedsLength = auditUnauditeds.content ? auditUnauditeds.content.length : 0 
    return (
     <div style={{ display: isShow ? 'unset' : 'none' }} className={`${auditUnauditedsLength > 0 && !loadAuditUnauditedsLoading? 'table-tr-height50' : 'table-tr-middle'}`}>
        <SearchBar
            searchText={params.idOrName}
            search={this.search}
            clear={this.clear}
            dataRange={{ from: params.applyStart, to: params.applyEnd }}
            changeDataRange={this.changeDataRange}
            changeSearchBox={this.changeSearchBox}
        />
        <DataTable 
          data={auditUnauditeds.content}
          columns={this.getColumns()}
          rowKey="id"
          loading={loadAuditUnauditedsLoading}
          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) { 
              return <span className="text-weak">暂无数据</span>; }
          }}
        />
        { auditUnauditedsLength > 0 && !loadAuditUnauditedsLoading ? <div className="page-padding20">
          <Pager 
            total={auditUnauditeds.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />
        </div> : '' }
        { viewDialogStatus &&
          <ViewDialog viewType="waitconfirm" viewObject={viewObject} handleCancel={this.handleCancelViewDialog}  />
        }
        <Dialog
          style={{ width: '347px', fontSize: '12px' }}
          title="通过确认"
          visible={auditPassDialogStatus}
          onCancel={this.handleCancelAuditPassDialog}
          footerRender={() => 
            <div className="tc-15-rich-dialog-ft-btn-wrap">
              <Button loading={auditPassLoading} onClick={this.handleOkAuditPassDialog}>确定通过</Button>
              <Button className="weak-button" onClick={this.handleCancelAuditPassDialog}>关闭</Button>
            </div>}
        >
          {/* <Tip hasClose={false} type="error">不通过！剩余可生成码量{100}，可生成的码量不足请联系相关部门，购买更多码量</Tip> */}
          码包会在通过后生成，确认后将自动发送码包给码包接收者<span style={{color: '#B43537'}}>{viewAudit.packetReceiver}</span>，密码接收者<span style={{color: '#B43537'}}>{viewAudit.passwordReceiver}</span>
        </Dialog>
        { notPassDialogStatus &&
          <NotPassForm onSubmit={this.handleSubmitNotPassForm} handleCancel={this.handleCancelNotPassForm} ></NotPassForm>        
        }
     </div>
    ) 
  }
}

export default NotAudit;