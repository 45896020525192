import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager } from 'tyb'

const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { loadAuditPasses } = iceStarkData.store.get('commonAction')?.batchApply;
import SearchBar from '../../components/SearchBar';
import ViewDialog from '../../components/ViewDialog';
import { formatDate, getColorByValue } from 'ucode-utils';
import { handSortOrder } from 'ucode-utils';
import enums from '@/assets/enum.json';
import '../index.scss';


@connect(
  state => ({
    factorySelections: state.factory.factorySelections,
    auditPasses: state.batchApply.auditPasses,
    loadAuditPassesLoading: state.batchApply.loadAuditPassesLoading,
  }),
  {
    loadSelections: loadSelections.REQUEST,
    loadAuditPasses: loadAuditPasses.REQUEST,
  }
)
class AlreadyAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCreatedTimeOrder: null,
      factoryNameFilter: [],
      tableColumns: this.getTabColumns(),
      perPageCountList: [20, 50, 100],
      params: {
        idOrName: '',
        applyStart: '',
        applyEnd: '',
        factoryIds: '',
        page: 0,
        size: 20,
        sort: ''
      },
      viewDialogStatus: false,
      viewObject: {}
    }
  }

  componentDidMount() {
    const { loadSelections, loadAuditPasses } = this.props;
    loadSelections({factoryType: 'PRINT'});
    loadAuditPasses(this.state.params);

  }

  changeSearchBox = (e) => {    
    const params = this.state.params;
    params.idOrName = e.target.value;
    this.setState({
      params
    })
  }

  changeDataRange = (value) => {
    const params = this.state.params;
    params.applyStart = isNaN(new Date(value.fromFormat).getTime()) ? '' :  new Date(value.fromFormat).getTime();
    params.applyEnd = isNaN(new Date(value.toFormat).getTime()) ? '' :  new Date(value.toFormat).getTime();
    this.setState({
      params
    })
  }

  search = () => {
    const params = this.state.params;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadAuditPasses(params);
  }

  clear = () => {
    const params = this.state.params;
    params.idOrName = '';
    params.applyStart = '';
    params.applyEnd = '';
    this.setState({
      params
    })
  }

  getTabColumns = () => {
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: '码包名称/ID',
        render: (value, record) => <div>
          <div title={record.name}><span className="text-overflow">{record.name}</span></div>
          <div title={value}><span className="text-overflow">{value}</span></div>
        </div>
      },
      {
        key: 'totalTagCount',
        dataIndex: 'totalTagCount',
        title: '申请码量',
        render: (value, record) => {
          if (record.combinationTagType == 'SINGLE_TAG') {
            return <div>
              <div title={value}><span className="text-overflow">{value}</span></div>
            </div>
          }
          else {
            return <div>
            <div title={`${value}(${record.unitCount}套)`}><span className="text-overflow">{value}({record.unitCount}套)</span></div>
          </div>
          }
        }
      },
      {
        key: 'eseConfigName',
        dataIndex: 'eseConfigName',
        title: '码印刷规格',
      },
      {
        key: 'combinationTagType',
        dataIndex: 'combinationTagType',
        title: '规格类型',
        render: (value) => <div title={enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}>
        <span>{enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}</span>
    </div>
      },
      {
        key: 'factoryName',
        dataIndex: 'factoryName',
        title: '包材印刷厂',
      },
      {
        key: 'applyName',
        dataIndex: 'applyName',
        title: '申请人',
      },
      {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: '审核时间',
        render: (value) => <div title={formatDate(value)}>
          <span className="text-overflow">{formatDate(value)}</span>
        </div>
      },
      {
        key: 'remark',
        dataIndex: 'remark',
        title: '申请备注',
      },
      {
        key: 'operation',
        dataIndex: 'operation',
        title: '状态',
        render: (value) => <div style={{ color: getColorByValue(value) }}>
          {enums.operateType[value] && enums.operateType[value].name}
        </div>
      },
      {
        key: 'op',
        dataIndex: 'op',
        title: '操作',
        render: (value, record) =>
          <div className="table-operate-btn">
            <span onClick={() => {
              this.setState({
                viewDialogStatus: true,
                viewObject: record
              })
            }}>查看</span>
          </div>
      },
    ]
  }

  handleSortCreatedTime = () => {
    const newOrder = handSortOrder(this.state.sortCreatedTimeOrder);
    const params = this.state.params;
    const sort = `details.created_time,${newOrder}`;
    params.sort = sort;
    this.setState({ params, sortCreatedTimeOrder: newOrder });
    this.props.loadAuditPasses(params);
  }

  renderCreatedTimeSortHeader = (col) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state.sortCreatedTimeOrder}
        onSort={this.handleSortCreatedTime}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    )
  }

  handleFactoryNameFilter = (value) => {
    
    const params = this.state.params;
    params.factoryIds = value;
    params.page = 0;
    this.setState({
      params,
      factoryNameFilter: value
    });
    this.props.loadAuditPasses(params);
  }

  renderFactoryNameFilterHeader = (col) => {
    const { factorySelections = [] } = this.props;
    const list = factorySelections.map(v => {
      return {
        text: v.name,
        value: v.id
      }
    })

    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={this.state.factoryNameFilter}
        onFilter={this.handleFactoryNameFilter}
      >
        {col.title}
      </DataTable.FilterableColumnHeader>
    )
  }



  getColumns = () => {
    return this.state.tableColumns.map(column => {
      if (column.key === 'createdTime') {
        return {
          ...column,
          headerRender: this.renderCreatedTimeSortHeader
        }
      } else if (column.key === 'factoryName') {
        return {
          ...column,
          headerRender: this.renderFactoryNameFilterHeader
        }
      } else {
        return column;
      }
    });
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loadAuditPasses(params);
  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadAuditPasses(params);
  }

  handleCancelViewDialog = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {}
    })
  }



  render() {
    const { params, perPageCountList, viewDialogStatus, viewObject } = this.state;
    const { isShow, auditPasses = {}, loadAuditPassesLoading } = this.props;
    const auditPassesLength = auditPasses.content ? auditPasses.content.length : 0 
    return (
     <div style={{ display: isShow ? 'unset' : 'none' }} className={`${auditPassesLength > 0 && !loadAuditPassesLoading? 'table-tr-height50' : 'table-tr-middle'}`}>
        <SearchBar
            searchText={params.idOrName}
            search={this.search}
            clear={this.clear}
            dataRange={{ from: params.applyStart, to: params.applyEnd }}
            changeDataRange={this.changeDataRange}
            changeSearchBox={this.changeSearchBox}
        />
        <DataTable 
          data={auditPasses.content}
          columns={this.getColumns()}
          rowKey="index"
          loading={loadAuditPassesLoading}
          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) { 
              return <span className="text-weak">暂无数据</span>; }
          }}
        />
        { auditPassesLength > 0 && !loadAuditPassesLoading ? <div className="page-padding20">
          <Pager 
            total={auditPasses.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />
        </div> : '' }
        { viewDialogStatus &&
          <ViewDialog  viewType="audit" viewObject={viewObject} handleCancel={this.handleCancelViewDialog}  /> 
        }
     </div>
    ) 
  }
}

export default AlreadyAudit;